import React from 'react';
import './Landing.scss';

function Landing() {
  return (
    <div className="Landing">

      <h2>A colourful puzzle game for iOS. <a target="_blank" href="https://apps.apple.com/gb/app/colour-heist/id1509460643" rel="noopener noreferrer">Free download</a></h2>
      {/* <h2>Coming soon for iOS.</h2> */}

    </div>
  );
}

export default Landing
