import React from 'react';
import './Privacy.scss';

function Privacy() {
  return (
    <div className="Privacy">

      <h2>EW Digital Ltd. Privacy Policy</h2>

      <p>
        This Privacy Policy describes how your personal information is collected, used, and shared when you use Colour Heist for iOS (the "App").
					</p>
      <p>Your privacy is important to us. It is EW Digital Ltd.'s policy to respect your privacy regarding any information we may collect from you through the App.</p>
      <p>
        Your continued use of the the App will be regarded as acceptance of our practices around privacy and personal information.
					</p>

      <p className="privacy-section-heading">
        PERSONAL INFORMATION WE COLLECT
					</p>
      <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent.</p>
      <p>
        When you use the App, we automatically collect certain information about your device, including information about your web browser, IP address, and time zone. We refer to this automatically-collected information as "Device Information."
					</p>
      <p>
        We collect Device Information using the following technologies:
					</p>
      <ul>
        <li>
          "Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
						</li>
      </ul>
      
      <p>
        When we talk about "Personal Information" in this Privacy Policy, we are talking about Device Information.
					</p>
      <p className="privacy-section-heading">
        HOW DO WE USE YOUR PERSONAL INFORMATION?
					</p>
      <p>
        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and to assess the success of our marketing and advertising campaigns).
					</p>
      <p className="privacy-section-heading">

        SHARING YOUR PERSONAL INFORMATION
					</p>
      <p>
        We only share your Personal Information with third parties to help us use your Personal Information, as described above.  For example, we use Auth0 to handle our authentication--you can learn more about how Auth0 here: https://auth0.com/.
					</p>
      <p>The App may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
      <p>
        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a lawful request for information we receive, or to otherwise protect our rights.
					</p>
      <p className="privacy-section-heading">
        YOUR RIGHTS
					</p>
      <p>
        If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
					</p>
      <p>
        Additionally, if you are a European resident we note that we are processing your information in order to provide a service to you, and to pursue our legitimate business interests listed above. Additionally, please note that your information may be transferred outside of Europe.
					</p>
      <p className="privacy-section-heading">
        CHANGES
					</p>
      <p>
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
					</p>
      <p className="privacy-section-heading">
        CONTACT US
					</p>
      <p>
        For more information about our privacy practices, or if you have questions or comments, please contact us by e-mail at colourhiest@madebyew.com.
					</p>

    </div>
  );
}

export default Privacy
