import React from 'react';
import logo from './assets/images/colour-heist-logo.svg';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Landing from './routes/landing/Landing';
import Privacy from './routes/privacy/Privacy';

function App() {
  // const routerBaseName = process.env.NODE_ENV === 'development' ? '' : 'colour-heist';

  return (

    // <Router basename={routerBaseName}>
    <Router>
      <div className="App">

        <header>
          <div className='logo'>
            <Link to={'/'}><img src={logo} className="logo" alt="Colour Heist" /></Link>
            <h1>
              <Link to={'/'}>Colour Heist</Link>
              {/* <a href="/">Colour Heist</a> */}
            </h1>
          </div>
          {/* <nav className='nav'>
            <ul>
              <li>
                <Link to="/">About</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
            </ul>
          </nav> */}
        </header>




        {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}


        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
        </Switch>




        <footer>
          <ul>
            {/* <li><Link to={routerBaseName + 'privacy'}>Pri</Link></li>
          <li><Link to={'privacy'}>Pri 2</Link></li> */}
            <li> <a href="https://striketype.com" target="_blank" rel="noopener noreferrer">Made by Striketype</a></li>
            {/* <li><a href="/privacy">Privacy Policy</a></li> */}
            <li><Link to={'/privacy'}>Privacy Policy</Link></li>
            <li>&copy; 2019-{new Date().getFullYear()} Striketype</li>
          </ul>
        </footer>




      </div>
    </Router>



  );
}

export default App;
